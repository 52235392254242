<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>直播管理</el-breadcrumb-item>
      <el-breadcrumb-item>直播管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button size="small" type="warning" style="float:left;margin-right:20px" @click="addLive('new','')">添加</el-button>
      <!-- <p class="searchp" style="float:left;margin:0;"> -->
        <!-- <span>类型：</span> -->
        <el-select filterable v-model="type" clearable placeholder="请选择直播状态" style="width:160px;margin-right:20px;float:left;" size="small">
          <el-option v-for="item in typeoptions" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      <!-- </p> -->
      <el-button type="primary" @click="getList(1)" size="small">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" label="ID"></el-table-column>
      <el-table-column prop="title" label="标题"></el-table-column>
      <el-table-column prop="userName" label="直播人"></el-table-column>
      <el-table-column prop="screenType" label="屏幕类型">
        <template slot-scope="scope">
          <span>{{filtetscreentype(scope.row.screenType)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="startTime" label="开始时间"></el-table-column>
      <el-table-column prop="endTime" label="结束时间"></el-table-column>
      <el-table-column prop="liveUrl" label="回放地址"></el-table-column>
      <el-table-column label="推流地址">
        <template slot-scope="scope">
          {{scope.row.recUrl}}
          <span class="copybtn" @click="copyUrl(scope.row,'rec')">点击复制</span>
        </template>
      </el-table-column>
      <!-- <el-table-column label="录播地址">
        <template slot-scope="scope">
          {{scope.row.recUrl}}
          <span class="copybtn" @click="copyUrl(scope.row,'rec')">点击复制</span>
        </template>
      </el-table-column>
      <el-table-column label="直播地址">
        <template slot-scope="scope">
          {{scope.row.liveUrl}}
          <span class="copybtn" @click="copyUrl(scope.row,'live')">点击复制</span>
        </template>
      </el-table-column> -->
      <!-- <el-table-column label="直播地址">
                <template slot-scope="scope">
                    {{scope.row.recUrl}}
                    <span class="copybtn" @click="copyUrl(scope.row)">点击复制</span>
                </template>
            </el-table-column> -->
      <el-table-column width="220">
        <template slot-scope="scope">
          <div class="opt">
            <p>
              <!-- <el-button size="mini" type="warning" icon="el-icon-edit" @click="addLive('bj',scope.row)">编辑 </el-button> -->
              <el-button size="mini" type="success" v-if="scope.row.status!=-1" icon="el-icon-view" @click="look(scope.row)">查看
              </el-button>
            </p>
            <p>
              <el-button size="mini" type="danger" icon="el-icon-delete" @click="delLive(scope.row, scope.$index)">删除
              </el-button>
              <!-- <el-button @click="startLive(scope.row)">开始直播</el-button> -->
            </p>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <p class="paging">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current" :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper" :total="totalCount" :hide-on-single-page="value"></el-pagination>
    </p>
    <el-dialog title="选择直播地址" :visible.sync="selectPT" width="30%">
      <el-select v-model="choicePT" clearable placeholder="请选择">
        <el-option v-for="item in selectoptions" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="(selectPT = false), (choicePT = '')">取 消</el-button>
        <el-button type="primary" @click="sub">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="" :visible.sync="dialogVisible" width="30%">
      <span>{{ LiveUrl }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeLiveTC">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// var Liveserve = "http://192.168.110.220:20210/";
var Liveserve = "https://live-server.51xk.info/";
export default {
  inject: ["reload"],
  data() {
    return {
      list: [],
      selectPT: false,
      selectoptions: [],
      selectrow: "",
      choicePT: "",
      LiveUrl: "",
      dialogVisible: false,
      type: "",
      typeoptions: [
        { id: -1, name: "断流" },
        { id: 0, name: "未开始" },
        { id: 1, name: "直播中" },
        { id: 2, name: "回放" },
      ],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
    };
  },
  mounted() {
    let _this = this;
    // this.getList();
  },
  methods: {
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getList(currentPage);
    },
    getList(page) {
      this.axios
        .get(
          Liveserve +
            "api/Live/get_live_list2?status=" +
            this.type +
            "&index=" +
            page +
            "&pagesize=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.list = res.data.result.list;
            this.totalCount = res.data.result.count;
          }
        });
    },
    addLive(type, row) {
      if (type == "bj") {
        this.$router.push({
          path: "/liveManage/liveDetail",
          query: { type: type, id: row.id },
        });
      } else {
        this.$router.push({
          path: "/liveManage/liveDetail",
          query: { type: type },
        });
      }
    },
    filtetscreentype(str) {
      var _str = "";
      if (str == 1) {
        _str = "横屏";
      } else if(str == 0) {
        _str = "竖屏";
      }else{
        _str = '';
      }
      return _str;
    },
    delLive(row, index) {
      this.$confirm("此操作将永久删除该直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post(Liveserve + "api/Live/del", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 1) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.result);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    startLive(row) {
      this.selectoptions = [];
      this.selectPT = true;
      this.selectrow = row;
      this.axios
        .get(Liveserve + "api/LiveDomain/get_live_domain_list")
        .then((res) => {
          if (res.data.code == 1) {
            console.log(res);
            this.selectoptions = res.data.result;
          }
        });
    },
    sub() {
      console.log({ id: this.selectrow.id, liveDomainID: this.choicePT });
      this.axios
        .post(
          Liveserve + "api/Live/create_live",
          this.qs.stringify({
            id: this.selectrow.id,
            liveDomainID: this.choicePT,
          })
        )
        .then((res) => {
          if (res.data.code == 1) {
            console.log(res);
            this.selectPT = false;
            this.dialogVisible = true;
            this.LiveUrl = res.data.result;
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
    copyUrl(row, type) {
      let Url2;
      if (type == "live") {
        Url2 = row.liveUrl;
      } else {
        Url2 = row.recUrl; //每一行的某个值，如选中的当前行的url
      }
      var oInput = document.createElement("input"); //创建一个隐藏input（重要！）
      oInput.value = Url2; //赋值
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.$message({
        type: "success",
        message: "复制成功",
      });
    },
    closeLiveTC() {
      this.dialogVisible = false;
      this.reload();
    },
    look(row) {
      //   this.$router.push({ path: "LiveDetaillook", query: { url: row.liveUrl } });
      //   this.videourl = 'http://cdn.51xk.info/'+row.fileList;
      let routeData = this.$router.resolve({
        path: "/backvideo/videoLookReplay",
        query: { des: row.title, url: row.liveUrl },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.copybtn {
  cursor: pointer;
  color: green;
}
.opt .el-button {
  margin-left: 10px;
}
</style>
